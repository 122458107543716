body { 
    font-family: 'Alegreya', serif;
    max-width: 1280px;
    display: table-cell;
    vertical-align: middle;
    padding: 0px;
    margin: 0px;
}

html {
    display: table;
    margin: auto;
}