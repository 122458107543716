.a4 {
    aspect-ratio: 26 / 36;

    /* Character Name text */
    .character-name-text {
        top: 8.4%;
        left: 6%;
        max-width: 27.5%;
    }

    /* Class name text */
    .class-name {
        top: 12.75%;
        left: 9%;
        width: 20%;
    }

    /* Class and Abilities text */
    .class-features {
        top: 15.2%;
        left: 6.2%;
        width: 19.7%;
    }

    /* max hp text */
    .hp {
        top: 4.8%;
        left: 48.8%;
    }

    /* weapon text */
    .weapon {
        top: 8.5%;
        left: 57.5%;
        max-width: 33%;
    }

    /* strength stat text */
    .strength {
        top: 17.25%;
        left: 52%;
    }

    /* agility stat text */
    .agility {
        top: 21.25%;
        left: 52.25%;
    }

    /* presence stat text */
    .presence {
        top: 24.9%;
        left: 52%;
    }

    /* toughness stat text */
    .toughness {
        top: 29.0%;
        left: 51%;
    }

    /* spirit stat text */
    .spirit {
        top: 32.5%;
        left: 48.4%;
    }

    /* equipment text */
    .clothes-text {
        top: 14%;
        left: 60%;
        max-width: 23.5%;
    }

    /* equipment text */
    .equipment {
        top: 25%;
        left: 63%;
        max-width: 30%;
    }

    /* relic text */
    .relic {
        top: 31.5%;
        left: 7%;
        max-width: 24%;
    }

    /* devils luck circles */
    .devils-luck-circle-one {
        top: 43.9%;
        left: 36.2%;
    }

    /* devils luck circles */
    .devils-luck-circle-two {
        top: 43.8%;
        left: 38.8%;
    }

    /* devils luck circles */
    .devils-luck-circle-three {
        top: 43.8%;
        left: 41.5%;
    }

    /* terrible trait 1 text */
    .character-background {
        top: 54%;
        left: 5.5%;
        max-width: 22%;
    }

    /* distinctive flaw text */
    .distinctive-flaw {
        top: 62%;
        left: 6%;
        max-width: 20%;
    }

    /* Broken body text */
    .physical-ailment {
        top: 69.5%;
        left: 6%;
        max-width: 22%;
    }

    /* bad habit text */
    .idiosyncracies {
        top: 77.25%;
        left: 5%;
        max-width: 22%;
    }

    /* troubling tale text */
    .unfortunate-incidents {
        top: 84.5%;
        left: 5%;
        max-width: 23%;
    }

    /* thing of importance text */
    .thing-of-importance {
        top: 93.25%;
        left: 7%;
        max-width: 20%;
    }
}

.us-letter {
    aspect-ratio: 17 / 22;

    /* Character Name text */
    .character-name-text {
        top: 9.2%;
        left:6.8%;
        max-width: 26.6%;
    }

    /* Class name text */
    .class-name {
        top: 13.8%;
        left: 10.0%;
        width: 19%;
    }

    /* Class and Abilities text */
    .class-features {
        top: 16.3%;
        left: 7.2%;
        width: 20%;
    }

    /* max hp text */
    .hp {
        top: 5%;
        left: 47.8%;
    }

    /* weapon text */
    .weapon {
        top: 4.5%;
        left: 54.2%;
        max-width: 35%;
    }

    /* strength stat text */
    .strength {
        top: 18.4%;
        left: 51.0%;
    }

    /* agility stat text */
    .agility {
        top: 22.6%;
        left: 51.5%;
    }

    /* presence stat text */
    .presence {
        top: 26.4%;
        left: 51.2%;
    }

    /* toughness stat text */
    .toughness {
        top: 30.6%;
        left: 50.2%;
    }

    /* spirit stat text */
    .spirit {
        top: 34.0%;
        left: 47.8%;
    }

    /* equipment text */
    .clothes-text {
        top: 14.8%;
        left: 57.5%;
        max-width: 23.7%;
    }

    /* equipment text */
    .equipment {
        top: 26%;
        left: 61.4%;
        max-width: 31%;
    }

    /* relic text */
    .relic {
        top: 32.5%;
        left: 7.5%;
        max-width: 23.5%;
    }

    /* devils luck circles */
    .devils-luck-circle-one {
        top: 45.8%;
        left: 36.0%;
    }

    /* devils luck circles */
    .devils-luck-circle-two {
        top: 45.8%;
        left: 38.6%;
    }

    /* devils luck circles */
    .devils-luck-circle-three {
        top: 45.8%;
        left: 41.1%;
    }

    /* terrible trait 1 text */
    .character-background {
        top: 54%;
        left: 6.0%;
        max-width: 22%;
    }

    /* distinctive flaw text */
    .distinctive-flaw {
        top: 62.5%;
        left: 6.0%;
        max-width: 20%;
    }

    /* Broken body text */
    .physical-ailment {
        top: 70.2%;
        left: 6.0%;
        max-width: 22%;
    }

    /* bad habit text */
    .idiosyncracies {
        top: 78.2%;
        left: 6.0%;
        max-width: 22%;
    }

    /* troubling tale text */
    .unfortunate-incidents {
        top: 85.8%;
        left: 5.8%;
        max-width: 23%;
    }

    /* thing of importance text */
    .thing-of-importance {
        top: 94.6%;
        left: 8.2%;
        max-width: 20%;
    }
}

.us-letter.printer-friendly {
    aspect-ratio: 17 / 22;

    /* Character Name text */
    .character-name-text {
        top: 8.6%;
        left:7.7%;
        max-width: 26.5%;
    }

    /* Class name text */
    .class-name {
        top: 13.25%;
        left: 10.5%;
        width: 19%;
    }

    /* Class and Abilities text */
    .class-features {
        top: 15.7%;
        left: 7.8%;
        width: 20%;
    }

    /* max hp text */
    .hp {
        top: 5%;
        left: 48.5%;
    }

    /* weapon text */
    .weapon {
        top: 8.5%;
        left: 56.5%;
    }

    /* strength stat text */
    .strength {
        top: 17.5%;
        left: 51.8%;
    }

    /* agility stat text */
    .agility {
        top: 22%;
        left: 51.8%;
    }

    /* presence stat text */
    .presence {
        top: 25.7%;
        left: 51.7%;
    }

    /* toughness stat text */
    .toughness {
        top: 29.9%;
        left: 50.7%;
    }

    /* spirit stat text */
    .spirit {
        top: 33.3%;
        left: 48.2%;
    }

    /* equipment text */
    .clothes-text {
        top: 14.2%;
        left: 58.8%;
        max-width: 23.7%;
    }

    /* equipment text */
    .equipment {
        top: 25%;
        left: 63%;
        max-width: 30%;
    }

    /* relic text */
    .relic {
        top: 32.5%;
        left: 8%;
        max-width: 24%;
    }

    /* devils luck circles */
    .devils-luck-circle-one {
        top: 45%;
        left: 36.7%;
    }

    /* devils luck circles */
    .devils-luck-circle-two {
        top: 44.9%;
        left: 39.2%;
    }

    /* devils luck circles */
    .devils-luck-circle-three {
        top: 45%;
        left: 41.8%;
    }

    /* terrible trait 1 text */
    .character-background {
        top: 54%;
        left: 6.5%;
        max-width: 22%;
    }

    /* distinctive flaw text */
    .distinctive-flaw {
        top: 62.5%;
        left: 6.5%;
        max-width: 20%;
    }

    /* Broken body text */
    .physical-ailment {
        top: 70.2%;
        left: 6.5%;
        max-width: 22%;
    }

    /* bad habit text */
    .idiosyncracies {
        top: 78.2%;
        left: 6.4%;
        max-width: 22%;
    }

    /* troubling tale text */
    .unfortunate-incidents {
        top: 85.8%;
        left: 6.4%;
        max-width: 23%;
    }

    /* thing of importance text */
    .thing-of-importance {
        top: 95.4%;
        left: 8.2%;
        max-width: 20%;
    }
}

/* Container holding the image and the text */
.container {
    position: relative;
    text-align: left;
    background-color: white;
    width: 100%;
}

/* Background character sheet image*/
.character-sheet {
    width: inherit;
}

/* Character Name text */
.character-name-text {
    position:absolute;
    font-weight:bold;
    display: flex;
    align-items: flex-end; 
    height: 3.5%;
    line-height: 1;
}

.character-name-text span {
    background-color: white;
}

/* Class name text */
.class-name {
    position: absolute;
}

/* Class and Abilities text */
.class-features {
    position: absolute;
    white-space: pre-line;
    background-color: white;
    line-height: 1.05;
}

/* max hp text */
.hp {
    position: absolute;
    font-weight: bold;
}

/* weapon text */
.weapon {
    position: absolute;
    background-color: white;
    max-width: 33%;
}

/* strength stat text */
.strength {
    position: absolute;
    font-weight: bold;
}

/* agility stat text */
.agility {
    position: absolute;
    font-weight: bold;
}

/* presence stat text */
.presence {
    position: absolute;
    font-weight: bold;
}

/* toughness stat text */
.toughness {
    position: absolute;
    font-weight: bold;
}

/* spirit stat text */
.spirit {
    position: absolute;
    font-weight: bold;
}

/* equipment text */
.clothes-text {
    position: absolute;
    line-height: 1.05;
    background-color: white;
}

/* equipment text */
.equipment {
    position: absolute;
}

/* devils luck circles */
.devils-luck-circle-one {
    position: absolute;
    font-weight: bold;
    font-family: unset;
}

/* devils luck circles */
.devils-luck-circle-two {
    position: absolute;
    font-weight: bold;
    font-family: unset;
}

/* devils luck circles */
.devils-luck-circle-three {
    position: absolute;
    font-weight: bold;
    font-family: unset;
}

/* terrible trait 1 text */
.character-background {
    position: absolute;
}

/* distinctive flaw text */
.distinctive-flaw {
    position: absolute;
}

/* Broken body text */
.physical-ailment {
    position: absolute;
}

/* bad habit text */
.idiosyncracies {
    position: absolute;
}

/* troubling tale text */
.unfortunate-incidents {
    position: absolute;
}

.thing-of-importance {
    position: absolute;
}

/* relic text */
.relic {
    position: absolute;
}

/* Centered text */
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} 